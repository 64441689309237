import { Route, Switch } from 'react-router-dom';
import './App.css';


function App() {
  return (
   <main>
            <Switch>
               <Route path='/' component={() => { 
                window.location.replace('https://outlook.office365.com/owa/calendar/TysonTeamMemberCompanyStore@TysonOnline.onmicrosoft.com/bookings/'); 
                return null;
            }}/>
            </Switch>
        </main>
  );
}

export default App;
